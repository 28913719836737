import cx from 'classnames'

import NavLinks from './Links'
import config from '~/../site-config.js'

import navStyles from './Nav.module.scss'

const NavDrawer = ({ mobileMenu }) => {
  const { customer, legal } = config.footer

  return (
    <div
      className={cx('drawer', 'drawer--left', navStyles.drawer, {
        'drawer--open': mobileMenu
      })}
      aria-label="Nav Drawer"
      aria-hidden={mobileMenu ? 'false' : 'true'}>
      <div
        className={cx('drawer__content', 'font--utility-3', navStyles.content)}>
        <div className={navStyles.container}>
          <NavLinks links={config.header.nav} />
          {/* Customer nav */}
          <NavLinks links={customer} />
          {/* Legal nav */}
          <NavLinks links={legal} className={navStyles.linksLegal} />
        </div>
      </div>
    </div>
  )
}
export default NavDrawer
