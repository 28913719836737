import Link from 'next/link'
import linksStyles from './NavLinks.module.scss'

const NavLinks = ({ links, className }) => {
  return (
    <ul
      className={`${linksStyles.list} ${className}`}
      role="menu"
      aria-label="Mobile Menu">
      {links.map((item, index) => {
        const { name, slug, query, isCky, target } = item
        return (
          <li className={linksStyles.list_item} key={index}>
            <Link href={query ? { pathname: slug, query: query } : slug}>
              <a className={isCky ? 'cky-banner-element' : ''} target={target}>
                {name}
              </a>
            </Link>
          </li>
        )
      })}
    </ul>
  )
}
export default NavLinks
